import React from 'react'
import { Link } from 'gatsby'

import { Helmet } from 'react-helmet'

import projectStyles from '../style.module.css'
import styles from './v-p-n.module.css'

const VPN = (props) => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>VPN - Covert_Bear</title>
        <meta property="og:title" content="VPN - Covert_Bear" />
      </Helmet>
      <div data-role="Header" className={styles['navbar-container']}>
        <div className={styles['navbar']}>
          <div className={styles['left-side']}>
            <Link to="/" className={styles['navlink']}>
              <img
                alt="image"
                src="/playground_assets/icon-white-200h.png"
                className={styles['image']}
              />
            </Link>
            <div data-type="BurgerMenu" className={styles['burger-menu']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon']}>
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className={styles['links-container']}>
              <Link
                to="/"
                className={` ${styles['link']} ${projectStyles['anchor']} `}
              >
                Home
              </Link>
              <Link
                to="/pricing"
                className={` ${styles['link01']} ${projectStyles['anchor']} `}
              >
                Pricing
              </Link>
              <a
                href="#vpn_heading"
                className={` ${styles['link02']} ${projectStyles['anchor']} `}
              >
                VPN
              </a>
              <Link
                to="/windows-r-d-p"
                className={` ${styles['link03']} ${projectStyles['anchor']} `}
              >
                Windows RDP
              </Link>
              <Link
                to="/linux-r-d-p"
                className={` ${styles['link04']} ${projectStyles['anchor']} `}
              >
                Linux RDP
              </Link>
            </div>
          </div>
          <div className={styles['right-side']}>
            <Link
              to="/contact1"
              className={` ${styles['cta-btn']} ${projectStyles['anchor']} ${projectStyles['button']} `}
            >
              Contact Us
            </Link>
          </div>
          <div data-type="MobileMenu" className={styles['mobile-menu']}>
            <div className={styles['container1']}>
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className={styles['image1']}
              />
              <div data-type="CloseMobileMenu" className={styles['close-menu']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className={styles['links-container1']}>
              <a
                href="#resources"
                className={` ${styles['link05']} ${projectStyles['anchor']} `}
              >
                Resources
              </a>
              <span
                className={` ${styles['link06']} ${projectStyles['anchor']} `}
              >
                Inspiration
              </span>
              <a
                href="#process"
                className={` ${styles['link07']} ${projectStyles['anchor']} `}
              >
                Process
              </a>
              <span
                className={` ${styles['link08']} ${projectStyles['anchor']} `}
              >
                Our story
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['services']}>
        <div className={styles['heading-container']}>
          <h1
            id="vpn_heading"
            className={` ${styles['text']} ${projectStyles['section-heading']} `}
          >
            Covert Bear VPN
          </h1>
          <span
            className={` ${styles['text001']} ${projectStyles['section-text']} `}
          >
            We&apos;ve got you covered
          </span>
        </div>
        <div className={styles['cards-container']}>
          <div className={styles['service-card']}>
            <h3
              className={` ${styles['text002']} ${projectStyles['card-heading']} `}
            >
              Life without a VPN
            </h3>
            <img
              alt="image"
              src="/playground_assets/no-vpn-1200w.gif"
              className={styles['image2']}
            />
            <span
              className={` ${styles['text003']} ${projectStyles['card-text']} `}
            >
              <span>What happens when you use the internet?</span>
              <br></br>
              <span></span>
              <br></br>
              <span className={styles['text008']}>
                • Be
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text009']}>you</span>
              <span className={styles['text010']}>
                , an internet user sitting on your computer in the UK.
              </span>
              <br></br>
              <span className={styles['text012']}>
                • You type in &quot;what is a VPN&quot; on
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text013']}>
                Google or some other Search Engine (SE)
              </span>
              <span className={styles['text014']}>
                {' '}
                (SE), even ones like
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text015']}>Duckduckgo</span>
              <span className={styles['text016']}>, and hit Enter.</span>
              <br></br>
              <span className={styles['text017']}>
                • Behind the scenes, your computer sends out an internet packet
                to Google (or other Search Engine [SE]) to get you search
                results. The packet goes through your modem-router and out to
                your Internet Service Provider (ISP).
              </span>
              <br></br>
              <span className={styles['text018']}>
                •Your ISP notes down your IP address and sees that you are
                searching &quot;what is a VPN&quot;. It passes on the packet
                from Router to Router until it reaches your desired SE.
              </span>
              <br></br>
              <span className={styles['text020']}>
                • The SE notes down your IP address and what you are searching
                for. It gets the results and sends it back to you.
              </span>
              <br></br>
              <span className={styles['text021']}>
                •
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text022']}>
                Everytime
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text023']}>
                you access the Internet in this way, you&apos;re
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text024']}>
                exposed
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text025']}>
                to the Government, your ISP, Hackers, and of course the Servers
                you access.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span className={styles['text026']}>
                • Information is power, and all of these people want yours. A
                digital profile is being made with all of your likes and
                dislikes, this information is then sold so others can profit off
                of you! Throughout all of this, you are the one who loses.
              </span>
              <br></br>
              <span className={styles['text027']}>
                •
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text028']}>
                Everything
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text029']}>
                you do online is being recorded, what you search for, what you
                watch, etc.
              </span>
            </span>
          </div>
          <div className={styles['service-card1']}>
            <h3
              className={` ${styles['text030']} ${projectStyles['card-heading']} `}
            >
              How does a VPN help?
            </h3>
            <img
              alt="image"
              src="/playground_assets/with-vpn-1200w.gif"
              className={styles['image3']}
            />
            <span
              className={` ${styles['text031']} ${projectStyles['card-text']} `}
            >
              <span>I get Covert Bear, now what?</span>
              <br></br>
              <span>​</span>
              <br></br>
              <span>​</span>
              <span className={styles['text037']}>
                • First, pat yourself on the back. You&apos;ve made the right
                choice.
              </span>
              <br></br>
              <span className={styles['text038']}>
                • Be you, same internet user sitting on your computer in the UK.
              </span>
              <br></br>
              <span className={styles['text039']}>
                • You type in &quot;what is a VPN&quot; on your Search Engine
                (SE) and hit Enter.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span className={styles['text040']}>
                • Your computer sends an encrypted message to your VPN. It
                passes through your modem-router and out to your Internet
                Service Provider (ISP).
              </span>
              <br></br>
              <span className={styles['text041']}>
                • Your ISP looks at the packet,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text042']}>
                nothing in it makes sense
              </span>
              <span className={styles['text043']}>
                . It passes it on the packet from Router to Router until it
                reaches your VPN.
              </span>
              <br></br>
              <span className={styles['text044']}>
                • Your VPN decrypts the message, then forwards the search query
                to the SE.
              </span>
              <br></br>
              <span className={styles['text045']}>
                • The SE notes down the
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text046']}>IP address of the VPN</span>
              <span className={styles['text047']}>
                {' '}
                and what it believes the VPN is searching for.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span className={styles['text048']}>
                • It gets the results and sends them back to the VPN.
              </span>
              <br></br>
              <span className={styles['text049']}>
                • The VPN encrypts the packet and forwards it back to you.
                It&apos;s decrypted on your end.
              </span>
              <br></br>
              <span className={styles['text050']}>
                • Checkmate Government, ISP, Hackers, and Servers you access.
              </span>
              <br></br>
              <span className={styles['text051']}>
                • Note, in the image the VPN is located in the USA. Whenever you
                access the internet,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text052']}>
                it appears as if you are in the USA (or wherever your VPN is)
              </span>
              <span className={styles['text053']}>
                . Your Location is thus kept private.
              </span>
            </span>
          </div>
          <div className={styles['service-card2']}>
            <h3
              className={` ${styles['text054']} ${projectStyles['card-heading']} `}
            >
              Why don&apos;t I just get any VPN?
            </h3>
            <img
              alt="image"
              src="/playground_assets/one-vpn-many-users-mask-1200w.gif"
              className={styles['image4']}
            />
            <span
              className={` ${styles['text055']} ${projectStyles['card-text']} `}
            >
              <span>Why is getting a dedicated VPN such a big deal?</span>
              <br></br>
              <span className={styles['text058']}></span>
              <br></br>
              <span className={styles['text060']}>
                ​• Covert Bear just wants you to be happy, we won&apos;t force
                you into anything (like the Government or Corporations try to
                do)
              </span>
              <br></br>
              <span className={styles['text061']}>
                • With
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text062']}>Ordinary VPN Servers</span>
              <span className={styles['text063']}>
                , you share a Server with everyone else.
              </span>
              <br></br>
              <span className={styles['text064']}>
                • In the image, there are only 3 other computers shown, but in
                reality there would be thousands if not hundreds of thousands.
              </span>
              <br></br>
              <span className={styles['text065']}>
                • Suppose you try to access a website, such as YouTube or want
                to buy something on Amazon, or watch a video on Netflix, etc.
              </span>
              <br></br>
              <span className={styles['text066']}>
                • At any given moment, thousands of other people using the same
                VPN Server may try to access it too.
              </span>
              <br></br>
              <span className={styles['text067']}>
                • All of your computers send your packets to the VPN Server. All
                of these packets are encrypted and secure.
              </span>
              <br></br>
              <span className={styles['text068']}>
                • The VPN Server decrypts all of these packets and forwards them
                to the Web Server (for whatever service).
              </span>
              <br></br>
              <span className={styles['text069']}>
                • The Web Server sees thousands (4 in the image, we
                couldn&apos;t make a better GIF yet) of requests from
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text070']}>the same Computer</span>
              <span className={styles['text071']}>.</span>
              <br></br>
              <span className={styles['text072']}>
                • That&apos;s right, everything in the Red Box above appears to
                be a single Computer to the Internet.
              </span>
              <br></br>
              <span className={styles['text073']}>
                • The Web Server starts to get nervous, it asks if you&apos;re a
                Robot. You probably know this all too well. You waste precious
                time trying to prove you&apos;re a Human to a Machine.
              </span>
              <br></br>
              <span className={styles['text074']}>
                • In some severe cases, such as with Amazon, your account might
                get flagged (and the VPN Server definitely gets flagged as being
                a VPN) because they think one person has 1000+ accounts with
                them. It might even get blocked.
              </span>
              <span></span>
              <br></br>
              <br></br>
              <span>Why do they flag the VPN Server?</span>
              <br></br>
              <span>
                •
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text081']}>
                They make money knowing who you are and what you do, no one
                wants you to use a VPN.
              </span>
            </span>
          </div>
          <div className={styles['service-card3']}>
            <h3
              className={` ${styles['text082']} ${projectStyles['card-heading']} `}
            >
              So how does a Dedicated VPN Server work?
            </h3>
            <img
              alt="image"
              src="/playground_assets/with-dedicated-vpn-mask-1200w.gif"
              className={styles['image5']}
            />
            <span
              className={` ${styles['text083']} ${projectStyles['card-text']} `}
            >
              <span>
                {' '}
                I see the GIF, how is this any different than an Ordinary VPN?
              </span>
              <br></br>
              <br></br>
              <span className={styles['text087']}>​</span>
              <span>
                •
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text089']}></span>
              <span className={styles['text090']}>
                Imagine the thousands of people from the previous card each have
                a Dedicated VPN Server (with Covert Bear!)
              </span>
              <br></br>
              <span className={styles['text091']}>
                • Each individual user wants to access some Web Server (of
                course).
              </span>
              <br></br>
              <span className={styles['text092']}>
                • Each individual computer sends their own packet to their own
                VPN Server.
              </span>
              <br></br>
              <span className={styles['text093']}>
                • Each VPN Server does what it&apos;s supposed to do. It
                decrypts the packet and sends the request to the Web Server
                showing itself as the User.
              </span>
              <br></br>
              <span className={styles['text094']}>
                • The Web Server still gets thousands, if not hundreds of
                thousands, of requests, but this time they are
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text095']}>
                all from different computers
              </span>
              <span className={styles['text096']}>
                . That means it&apos;s business as usual, the Web Server does
                not panic, it accepts and everyone is happy.
              </span>
              <br></br>
              <span className={styles['text097']}>
                • Note,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text098']}>
                each colored block appears as One Single Computer to the
                Internet
              </span>
              <span className={styles['text099']}>
                , to the Servers as well.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className={styles['section-separator']}></div>
      <div className={styles['footer-container']}>
        <div className={styles['footer']}>
          <div className={styles['copyright-container']}>
            <span
              className={` ${styles['link09']} ${projectStyles['anchor']} `}
            >
              <span>© Copyright, 2021</span>
              <br></br>
              <span>Covert Bear, All Rights Reserved</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VPN
